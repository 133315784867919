import {
	Title,
	TitleLeave,
	Discription,
	DiscriptionLeave,
	Link,
	Section,
	GlobalBox,
	GlobalBoxFlex,
	GlobalBoxFlexAccord,
	ButDiv,
	GlobalImage,
	GlobalImageAccord,
	Item,
	YouTube,
	DownText,
	Number,
	Hero,
	AccordLi,
	NumberDiv,
	DiscriptionAc,
} from './Zoom.styled';
import {
	ChakraProvider,
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
} from '@chakra-ui/react';
import { Questions, Answer } from '../Accordion/Accordion.styled';
import React from 'react';
import { MinusIcon, AddIcon } from '@chakra-ui/icons';
import Container from '../Container/Container.styled';
import { Image } from '@chakra-ui/react';
import HeroZoom from '../../image/Zoom/hero_zoom.png';
import Image12 from '../../image/Zoom/image-1-2.png';
import Image34 from '../../image/Zoom/image-3-4.png';
import Image5 from '../../image/Zoom/image-5.png';
import Image6 from '../../image/Zoom/image-6.png';
import Image7 from '../../image/Zoom/image-7.png';
import Image8 from '../../image/Zoom/image-8.png';
import Image9 from '../../image/Zoom/image-9.png';
import Accord1 from '../../image/Zoom/accord1.png';
import Accord2 from '../../image/Zoom/accord2.png';
import Last from '../../image/Zoom/last.png';
import { theme } from 'constants/theme';

const ZoomHero = () => {
	return (
		<>
			<main>
				<Hero
					style={{
						backgroundColor: '#F5F6FE',
					}}
				>
					<Container>
						<Title>How to Create a Zoom Account</Title>
						<ul>
							<Item>
								<GlobalImage>
									<Image margin="0 auto" src={HeroZoom} alt="Zoom" />
								</GlobalImage>
								<GlobalBox>
									<Discription>
										SEEYOU makes using Zoom easier and gives you many new
										features. If you have a Zoom account, you simply connect to
										SEEYOU
									</Discription>
									<Discription>
										If you do not yet have a Zoom account, this is how to create
										one
									</Discription>
									<ButDiv>
										<Link href={'https://zoom.us/'} target="_blank">
											Create Zoom Account
										</Link>
									</ButDiv>
								</GlobalBox>
							</Item>
							<Item>
								<GlobalImage>
									<YouTube
										width="560"
										height="315"
										src="https://www.youtube.com/embed/QOUwumKCW7M"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									></YouTube>
								</GlobalImage>
								<GlobalBox>
									<Discription>
										YouTube has many good tutorials on how to create a Zoom
										account
									</Discription>
									<ButDiv>
										<Link
											href={
												'https://www.youtube.com/results?search_query=zoom+tutorial'
											}
											target="_blank"
										>
											Open YouTube
										</Link>
									</ButDiv>
								</GlobalBox>
							</Item>
						</ul>
					</Container>
				</Hero>
				<Section>
					<Container>
						<Title>Zoom + SEEYOU Getting Started Guide</Title>
						<ul>
							<Item>
								<GlobalImage>
									<Image margin="0 auto" src={Image12} alt="Zoom" />
								</GlobalImage>
								<GlobalBox>
									<GlobalBoxFlex>
										<NumberDiv>
											<Number>1</Number>
										</NumberDiv>
										<Discription>
											<DownText href="/downloads">Download</DownText> and
											install SEEYOU
										</Discription>
									</GlobalBoxFlex>
									<GlobalBoxFlex>
										<NumberDiv>
											<Number>2</Number>
										</NumberDiv>
										<Discription>Create your account</Discription>
									</GlobalBoxFlex>
								</GlobalBox>
							</Item>
							<Item>
								<GlobalImage>
									<Image margin="0 auto" src={Image34} alt="Zoom" />
								</GlobalImage>
								<GlobalBox>
									<GlobalBoxFlex>
										<NumberDiv>
											<Number>3</Number>
										</NumberDiv>
										<Discription>
											Click on: Shortcuts or the big Plus button
										</Discription>
									</GlobalBoxFlex>
									<GlobalBoxFlex>
										<NumberDiv>
											<Number>4</Number>
										</NumberDiv>
										<Discription>Select: Book calendar event</Discription>
									</GlobalBoxFlex>
								</GlobalBox>
							</Item>
							<Item>
								<GlobalImage>
									<Image margin="0 auto" src={Image5} alt="Zoom" />
								</GlobalImage>
								<GlobalBoxFlex>
									<NumberDiv>
										<Number>5</Number>
									</NumberDiv>
									<Discription>
										If you did not create an account using the Google or
										Microsoft SSO, you must connect your calendars manually
									</Discription>
								</GlobalBoxFlex>
							</Item>
							<Item>
								<GlobalImage>
									<Image margin="0 auto" src={Image6} alt="Zoom" />
								</GlobalImage>
								<GlobalBoxFlex>
									<NumberDiv>
										<Number>6</Number>
									</NumberDiv>
									<Discription>
										Once connected, you can now see all your events from your
										respective calendars in one joint calendar, Zoom events
										included
									</Discription>
								</GlobalBoxFlex>
							</Item>
							<Item>
								<GlobalImage>
									<Image margin="0 auto" src={Image7} alt="Zoom" />
								</GlobalImage>
								<GlobalBoxFlex>
									<NumberDiv>
										<Number>7</Number>
									</NumberDiv>
									<Discription>
										SEEYOU simplifies bookings. You can choose a preferred
										provider, yet easily change to another provider when booking
										events
									</Discription>
								</GlobalBoxFlex>
							</Item>
							<Item>
								<GlobalImage>
									<Image margin="0 auto" src={Image8} alt="Zoom" />
								</GlobalImage>
								<GlobalBoxFlex>
									<NumberDiv>
										<Number>8</Number>
									</NumberDiv>
									<Discription>
										All upcoming events show both on the app home page and in
										your calendar. From there it only takes one click to join
										the event
									</Discription>
								</GlobalBoxFlex>
							</Item>
							<Item>
								<GlobalImage>
									<Image margin="0 auto" src={Image9} alt="Zoom" />
								</GlobalImage>
								<GlobalBoxFlex>
									<NumberDiv>
										<Number>9</Number>
									</NumberDiv>
									<Discription>
										SEEYOU’s Universal Toolbar works seamlessly with Zoom.
										Allowing easy recording and instant access to virtual camera
										functions like Real-Time Rewind and the Selfie Loop
									</Discription>
								</GlobalBoxFlex>
							</Item>

							<ChakraProvider>
								<Box>
									<Accordion allowMultiple>
										<AccordionItem
											border={'none'}
											borderRadius="12px"
											backgroundColor="#ECF1FE"
										>
											{({ isExpanded }) => (
												<>
													<h2>
														<AccordionButton
															_hover={{
																backgroundColor: 'transparent',
																boxShadow: 'none',
															}}
															_focus={{
																boxShadow: 'none',
															}}
															paddingRight="40px"
														>
															<Box flex="1" textAlign="left">
																<Questions>
																	How to disconnect Zoom from SEEYOU
																</Questions>
															</Box>
															{isExpanded ? (
																<MinusIcon
																	fontSize="12px"
																	color={theme.colors.prim_fifteen}
																/>
															) : (
																<AddIcon
																	fontSize="12px"
																	color={theme.colors.prim_fifteen}
																/>
															)}
														</AccordionButton>
													</h2>
													<AccordionPanel pb={4}>
														<Answer>
															To disconnect Zoom from your SEEYOU account,
															follow the steps below.
														</Answer>
														<ul
															style={{
																margin: '0 auto',
															}}
														>
															<AccordLi>
																<GlobalImageAccord>
																	<Image
																		margin="0 auto"
																		src={Accord1}
																		alt="Zoom"
																	/>
																</GlobalImageAccord>
																<GlobalBoxFlexAccord>
																	<NumberDiv
																		style={{
																			backgroundColor: 'white',
																		}}
																	>
																		<Number>1</Number>
																	</NumberDiv>
																	<DiscriptionAc>
																		From your SEEYOU account, under your profile
																		info, click on Settings, then navigate to
																		the Connections tab
																	</DiscriptionAc>
																</GlobalBoxFlexAccord>
															</AccordLi>
															<AccordLi>
																<GlobalImageAccord>
																	<Image
																		margin="0 auto"
																		src={Accord2}
																		alt="Zoom"
																	/>
																</GlobalImageAccord>
																<GlobalBoxFlexAccord>
																	<NumberDiv
																		style={{
																			backgroundColor: 'white',
																		}}
																	>
																		<Number>2</Number>
																	</NumberDiv>
																	<DiscriptionAc>
																		Then click: Remove Access. Once
																		disconnected, you will have to reconnect to
																		create Zoom events with SEEYOU. You will,
																		however, still be able to use the SEEYOU
																		toolbar with Zoom and all your other
																		favorite programs
																	</DiscriptionAc>
																</GlobalBoxFlexAccord>
															</AccordLi>
														</ul>
													</AccordionPanel>
												</>
											)}
										</AccordionItem>
									</Accordion>
								</Box>
							</ChakraProvider>

							<div>
								<TitleLeave>Remove SEEYOU from Zoom</TitleLeave>
								<DiscriptionLeave>
									To uninstall the SEEYOU app from your Zoom account, follow the
									steps below.
								</DiscriptionLeave>
							</div>
							<Item
								style={{
									flexDirection: 'row-reverse',
								}}
							>
								<GlobalImage>
									<Image margin="0 auto" src={Last} alt="Zoom" />
								</GlobalImage>
								<GlobalBox>
									<div
										style={{
											display: 'flex',
										}}
									>
										<NumberDiv>
											<Number>1</Number>
										</NumberDiv>
										<Discription>
											Log in to your Zoom account and navigate to the Zoom App
											Marketplace.
										</Discription>
									</div>
									<div
										style={{
											display: 'flex',
										}}
									>
										<NumberDiv>
											<Number>2</Number>
										</NumberDiv>
										<Discription>
											Click Manage - Added Apps or search for the SEEYOU app
										</Discription>
									</div>
									<div
										style={{
											display: 'flex',
										}}
									>
										<NumberDiv>
											<Number>3</Number>
										</NumberDiv>
										<Discription>Click the 'Remove' button.</Discription>
									</div>
									<Discription>
										Note: Removing SEEYOU from your Zoom marketplace would mean
										you will no longer be able to create Zoom events within
										SEEYOU.
									</Discription>
								</GlobalBox>
							</Item>
						</ul>
					</Container>
				</Section>
			</main>
		</>
	);
};
export default ZoomHero;
