import { useState, useEffect, useRef } from 'react';
import {
	Link,
	Popup,
	PopupButton,
	Icon,
	PopupMenu,
	PopupItem,
} from './PopupMenu.styled';

import { useOnClickOutside } from 'hooks/OnClickOutside';
import IconDown from 'image/icons/down.png';
import IconUp from 'image/icons/up.png';

import { useHref } from 'react-router-dom';

const PopupHeader = (props) => {
	const {
		links,
		triggerName,
		handleLinkClick: toggleMobileMenu,
		height,
	} = props;
	const href = useHref();
	const popupRef = useRef(null);
	const [active, setActive] = useState(false);
	const [isPopupMenuOpen, setIsPopupMenuOpen] = useState(false);
	const handleClosePopupMenu = () => {
		setIsPopupMenuOpen(false);
	};

	const handleOpenPopupMenu = () => {
		setIsPopupMenuOpen(true);
	};

	const togglePopupMenu = () => {
		setIsPopupMenuOpen(!isPopupMenuOpen);
	};

	const handleLinkClick = () => {
		if (toggleMobileMenu) {
			toggleMobileMenu();
			handleClosePopupMenu();
		}
	};

	useEffect(() => {
		setIsPopupMenuOpen(false);
		if (links.find(({ to }) => to === href)) {
			setActive(true);
		} else {
			setActive(false);
		}
	}, [href]);

	useOnClickOutside(popupRef, handleClosePopupMenu, isPopupMenuOpen);

	return (
		<Popup
			className={isPopupMenuOpen ? 'open' : 'closed'}
			ref={popupRef}
			onMouseEnter={handleOpenPopupMenu}
			onMouseLeave={handleClosePopupMenu}
		>
			<PopupButton className={active ? 'active' : ''} onClick={togglePopupMenu}>
				{triggerName} <Icon src={isPopupMenuOpen ? IconUp : IconDown} />
			</PopupButton>
			<PopupMenu
				style={{ height: height }}
				className={isPopupMenuOpen ? 'open' : 'closed'}
			>
				{links.map(({ to, content, target }, index) => (
					<PopupItem key={index}>
						<Link to={to} onClick={handleLinkClick} target={target}>
							{content}
						</Link>
					</PopupItem>
				))}
			</PopupMenu>
		</Popup>
	);
};

export default PopupHeader;
