import Helmet from 'react-helmet';
import ZoomHero from '../../components/Zoom';
import { ScrollToTop } from 'hooks/ScrollToTop';
import Address from 'components/Address';

const Zoom = () => {
	ScrollToTop();
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://seeyou.eu/providers-zoom" />
			</Helmet>
			<ZoomHero />
			<Address />
		</>
	);
};
export default Zoom;
