import {
	HeroSection,
	HeroTitle,
	HeroDiscription,
	HeroContainer,
	HeroThumb,
	HeroImg,
	GlobalBox,
} from './Hero.styled';
import { theme } from 'constants/theme';

import HeroIllustration from 'image/hero/main-image.png';
import MediaQuery from 'components/MediaQuery';
import DesktopDownloadLinks from 'components/DesktopDownloadLinks';

const Hero = () => {
	return (
		<HeroSection>
			<HeroContainer>
				<HeroThumb>
					<HeroImg src={HeroIllustration} alt="hero illustration" />
				</HeroThumb>
				<GlobalBox>
					<HeroTitle>Free Screen Recording for Windows</HeroTitle>
					<HeroDiscription>
						SEEYOU's{' '}
						<span style={{ color: theme.colors.primary, fontWeight: 600 }}>
							Universal Recordings Toolbar
						</span>{' '}
						provides free and easy Screen Recordings with No Watermarks
					</HeroDiscription>
					<HeroDiscription>
						You get a Screen Recorder, Recordings Library and Hyperlinked Notes.
						Click on a note, and the recording opens where you made it
					</HeroDiscription>
					<HeroDiscription>
						Use Real-Time Rewind to check what was said earlier in a meeting,
						and switch your camera feed to a Selfie Loop when multitasking
					</HeroDiscription>
					<HeroDiscription>
						You also get a global calendar with uniform booking for Google Meet,
						Microsoft Teams, WebEx, and Zoom
					</HeroDiscription>

					<MediaQuery device={'tablet'}>
						<DesktopDownloadLinks />
					</MediaQuery>
					<MediaQuery device={'default'}>
						<DesktopDownloadLinks />
					</MediaQuery>
				</GlobalBox>
			</HeroContainer>
		</HeroSection>
	);
};

export default Hero;
