import {
	Title,
	Discription,
	Link,
	GlobalBox,
	ButDiv,
} from '../More/More.styled';
import { PrivacySection } from './PrivacyHero.styled';
import Container from '../Container/Container.styled';

const PrivacyHero = () => {
	const style = {
		title: {
			'font-size': '32px',
			'font-weight': '600',
		},
		link: {
			color: 'blue',
			'text-decoration': 'underline',
		},
		bulletList: {
			'padding-left': '32px',
		},
		bulletItem: {
			'list-style-type': 'disc',
		},
	};

	return (
		<>
			<PrivacySection>
				<Container>
					<GlobalBox>
						<div class="privacy-policy">
							<Title>SEEYOU Privacy Statement</Title>
							<p>
								<strong>Last updated:</strong> March 3rd, 2023
							</p>

							<p>
								<strong>
									THIS PRIVACY STATEMENT DESCRIBES THE PERSONAL DATA WE COLLECT
									AND/OR PROCESS (WHICH MAY INCLUDE COLLECTING, ORGANIZING,
									STRUCTURING, STORING, USING, OR DISCLOSING) TO PROVIDE
									PRODUCTS AND SERVICES OFFERED DIRECTLY BY SEEYOU LTD.
									(“SEEYOU”), INCLUDING SEEYOU’S WEBSITES AND ITS MEETINGS,
									WEBINARS, AND MESSAGING PLATFORM, AND SEEYOU APP MARKETPLACE
									(“SEEYOU PRODUCTS” OR “PRODUCTS”).
								</strong>
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#personal-data">
										What Personal Data Do We Receive?
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#use-data">
										How Do We Use Personal Data?
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#share-data">
										How Do We Share Personal Data?
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#visibility">
										Who Can See and Share My Personal Data When I Use SEEYOU?
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#rights">
										Privacy Rights and Choices
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#children">
										Children
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#contact">
										How to Contact Us
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#retention">
										Retention
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#european">
										European Data Protection Specific Information
									</a>
								</li>
								<br></br>
								<li style={style.bulletItem}>
									<a target="_blank" style={style.link} href="#changes">
										Changes to This Privacy Statement
									</a>
								</li>
							</ul>

							<br></br>

							<h2 style={style.title} id="personal-data">
								What Personal Data Do We Receive?
							</h2>

							<br></br>

							<p>
								Personal data is any information from or about an identified or
								identifiable person, including information that SEEYOU can
								associate with an individual person. We may collect, or process
								on behalf of our customers, the following categories of personal
								data when you use or interact with SEEYOU Products:
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									<strong>Account Information:</strong> Information associated
									with an account that licenses SEEYOU Products, which may
									include administrator name, contact information, account ID,
									billing information, and account plan information.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Profile and Participant Information:</strong>{' '}
									Information associated with the SEEYOU profile of a user who
									uses SEEYOU Products under a licensed account or that is
									provided by an unlicensed participant joining a meeting, which
									may include name, display name, picture, email address, phone
									number, job information, stated locale, user ID, or other
									information provided by the user or their account owner.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Contacts and Calendar Integrations:</strong> Contact
									information added by accounts or their users to create contact
									lists on SEEYOU Products, which may include contact
									information a user integrates from a third-party app. Users
									can also integrate their calendars from other services with
									their SEEYOU profile or account.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Settings:</strong> Information associated with the
									preferences and settings on a SEEYOU account or user profile,
									which may include audio and video settings, recording file
									location, screen sharing settings, and other settings and
									configuration information.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Registration Information:</strong> Information people
									provide when registering for a SEEYOU meeting, webinar or
									recording, which may include name and contact information,
									responses to registration questions, and other registration
									information requested by the host.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Device Information:</strong> Information about the
									computers, phones, and other devices people use when
									interacting with SEEYOU Products, which may include
									information about the speakers, microphone, camera, OS
									version, hard disk ID, PC name, MAC address, IP address (which
									may be used to infer general location at a city or country
									level), device attributes (like operating system version and
									battery level), WiFi information, and other device information
									(like Bluetooth signals).
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>
										Meeting, Webinar, and Messaging Content and Context:
									</strong>{' '}
									Content generated in meetings, webinars, or messages that are
									hosted on SEEYOU Products, which may include audio, video,
									in-meeting messages, chat messaging content, transcriptions,
									written feedback, responses to polls and Q&A, and files, as
									well as related context, such as invitation details, meeting
									or chat name, or meeting agenda. Content may contain your
									voice and image, depending on the account owner’s settings,
									what you choose to share, your settings, and what you do on
									SEEYOU Products.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Product and Website Usage:</strong> Information about
									how people and their devices interact with SEEYOU Products,
									such as: when participants join and leave a meeting; whether
									participants sent messages and who they message with;
									performance data; mouse movements, clicks, keystrokes or
									actions (such as mute/unmute or video on/off), and other user
									inputs that help SEEYOU to understand feature usage, improve
									product design, and suggest features; which third-party apps
									users add to a meeting or other Product and what information
									and actions the app is authorized to access and perform; use
									of third-party apps and the SEEYOU App Marketplace; features
									used (such as screen sharing, emojis, or filters); and other
									usage information and metrics. This also includes information
									about when and how people visit and interact with SEEYOU’s
									websites, including what pages they accessed, their
									interaction with the website features, and whether or not they
									signed up for a SEEYOU Product.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Communications with SEEYOU:</strong> Information about
									your communications with SEEYOU, including relating to support
									questions, your account, and other inquiries.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Information from Partners:</strong> SEEYOU obtains
									information about account owners and their users from
									third-party companies, such as market data enrichment
									services, including information about an account owner’s
									company size or industry, contact information, or activity of
									certain enterprise domains. SEEYOU may also obtain information
									from third-party advertising partners who deliver ads
									displayed on SEEYOU Products, such as whether you clicked on
									an ad they showed you.
								</li>
							</ul>

							<br></br>

							<h2 style={style.title}>Google Integration with SEEYOU:</h2>

							<br></br>

							<p>
								SEEYOU uses Google API services. For more information on
								Google’s privacy policy the user is advised to refer to{' '}
								<a
									target="_blank"
									style={style.link}
									href="https://policies.google.com/privacy"
								>
									Google Privacy Policy
								</a>
								. To revoke SEEYOU access within security settings in Google,
								please visit{' '}
								<a
									target="_blank"
									style={style.link}
									href="https://support.google.com/accounts/answer/3466521"
								>
									Google Account Settings
								</a>
								.
							</p>

							<br></br>

							<h2 style={style.title}>Zoom Integration with SEEYOU:</h2>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									SEEYOU allows for direct integration with Zoom, allowing the
									user to connect their Zoom account to their SEEYOU account.
									This then allows SEEYOU to pull any live Zoom meeting events,
									and allows users to book Zoom events within SEEYOU.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									The user may within the SEEYOU App, in settings, remove their
									Zoom connection, and upon doing that, SEEYOU will revoke the
									connection, and SEEYOU will no longer have access to fetch
									user any of the user’s Zoom meetings or book any Zoom events
									for the user within SEEYOU.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									A Zoom user has the right to revoke their Zoom connection
									inside the SEEYOU app. Once the user revokes their Zoom
									connection, SEEYOU will no longer have the right access any
									data (meeting events) from their Zoom account.
								</li>
							</ul>

							<br></br>

							<h2 style={style.title}>How Do We Use Personal Data?</h2>

							<br></br>

							<p>
								SEEYOU employees do not access meeting, webinar, or messaging
								content (specifically, audio, video, files, and messages) unless
								directed by an account owner, or as required for legal, safety,
								or security reasons, as discussed below. SEEYOU uses personal
								data to conduct the following activities:
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									<strong>Provide SEEYOU Products and Services:</strong> To
									provide Products, features, and services to account owners,
									their users, and those they invite to join meetings and
									webinars hosted on their accounts, including to customize
									SEEYOU Product features and recommendations for accounts or
									their users. SEEYOU also uses personal data, including contact
									information, to route invitations and messages to recipients
									when people send invitations and messages using SEEYOU
									Products. This may also include using personal data for
									customer support, which may include accessing audio, video,
									files, and messages, at the direction of the account owner or
									their users. We also use personal data to manage our
									relationship and contracts with account owners, including
									billing, compliance with contractual obligations, and related
									administration.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Product Research and Development:</strong> To develop,
									test, and improve SEEYOU Products, including, for example,
									content-related features (such as background filters), and to
									troubleshoot products and features.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>
										Marketing, Promotions, and Third-Party Advertising:
									</strong>{' '}
									To permit SEEYOU and/or its third party marketing partners to
									market, advertise, and promote SEEYOU Products, features, and
									services, including based on your product usage, information
									we receive from third-party partners, or if you visit our
									websites, information about how and when you visit, and your
									interactions with them. We may also use cookies or similar
									technology, including from third-party advertising partners,
									to show you ads within SEEYOU Products about products,
									services, or causes from third parties. SEEYOU does NOT use
									meeting, webinar, or messaging content (specifically, audio,
									video, files, and messages) for any marketing, promotions or
									third-party advertising purposes.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>
										Authentication, Integrity, Security, and Safety:
									</strong>{' '}
									To authenticate accounts and activity, detect, investigate,
									and prevent malicious conduct or unsafe experiences, address
									security threats, protect public safety, and secure SEEYOU
									Products.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Communicate with You:</strong> We use personal data
									(including contact information) to communicate with you about
									SEEYOU Products, features, and services, including product
									updates, your account, and changes to our policies and terms.
									We also use your information to respond to you when you
									contact us.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Legal Reasons:</strong> To comply with applicable law
									or respond to valid legal process, including from law
									enforcement or government agencies, to investigate or
									participate in civil discovery, litigation, or other
									adversarial legal proceedings, and to enforce or investigate
									potential violations of our Terms of Service or policies.
								</li>
							</ul>
							<p>
								SEEYOU uses advanced tools to automatically scan content such as
								virtual backgrounds, profile images, and files uploaded or
								exchanged through chat, for the purpose of detecting and
								preventing violations of our terms or policies and illegal or
								other harmful activity, and its employees may investigate such
								content where required for legal, safety, or security reasons.
							</p>

							<br></br>

							<h2 style={style.title}>How Do We Share Personal Data?</h2>

							<br></br>

							<p>
								SEEYOU provides personal data to third parties only with consent
								or in one of the following circumstances (subject to your prior
								consent where required under applicable law):
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									<strong>Resellers:</strong> If an account owner licensed or
									purchased SEEYOU Products from a third- party reseller of
									SEEYOU Products, the reseller may be able to access personal
									data and content for users, including meetings, webinars, and
									messages hosted by the account owner.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Vendors:</strong> SEEYOU works with third-party
									service providers to provide, support, and improve SEEYOU
									Products and technical infrastructure, and for business
									services such as payment processing. SEEYOU may also work with
									third-party service providers to provide advertisements and
									business analytics regarding SEEYOU Products. These vendors
									can access personal data subject to contractual and technical
									requirements for protecting personal data and prohibiting them
									from using personal data for any purpose other than to provide
									services to SEEYOU or as required by law.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>For Legal Reasons:</strong> SEEYOU may share personal
									data as needed to: (1) comply with applicable law or respond
									to, investigate, or participate in valid legal process and
									proceedings, including from law enforcement or government
									agencies; (2) enforce or investigate potential violations of
									its Terms of Service or policies; (3) detect, prevent, or
									investigate potential fraud, abuse, or safety and security
									concerns, including threats to the public; (4) meet our
									corporate and social responsibility commitments; (5) protect
									our and our customers’ rights and property; and (6) resolve
									disputes and enforce agreements.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>
										Marketing, Advertising, and Analytics Partners:
									</strong>{' '}
									SEEYOU uses third-party marketing, advertising, and analytics
									providers: to provide statistics and analysis about how people
									are using SEEYOU Products and our website; to provide
									advertising and marketing for SEEYOU Products, including
									targeted advertising based on your use of our website; and to
									show you third-party advertising within SEEYOU Products. These
									third-party partners may receive information about your
									activities on SEEYOU’s website through third-party cookies
									placed on SEEYOU’s website. To opt out of our use of
									third-party cookies that share data with these partners, visit
									our cookie management tool, available Cookies Settings. Where
									required by law, SEEYOU will first obtain your consent before
									engaging in the marketing or advertising activities described.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Corporate Affiliates:</strong> SEEYOU shares personal
									information with corporate affiliates, such as SEEYOU Voice
									Communications, Inc., to provide integrated and consistent
									experiences across SEEYOU Products (such as enabling a user to
									integrate a SEEYOU Phone call into a meeting) to detect,
									investigate, and prevent fraud, abuse, and threats to public
									safety.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Change of Control:</strong> We may share personal data
									with actual or prospective acquirers, their representatives
									and other relevant participants in, or during negotiations of,
									any sale, merger, acquisition, restructuring, or change in
									control involving all or a portion of SEEYOU’s business or
									assets, including in connection with bankruptcy or similar
									proceedings.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>YouTube:</strong> SEEYOU uses YouTube API services for
									video storing. When storing videos on YouTube Studio, the user
									is required to and advised to reference YouTube’s Terms of
									service{' '}
									<a
										target="_blank"
										style={style.link}
										href="https://www.youtube.com/static?template=terms"
									>
										YouTube's Terms of Service
									</a>
									.
								</li>
							</ul>

							<br></br>

							<h2 style={style.title}>
								Who Can See and Share My Personal Data When I Use SEEYOU?
							</h2>

							<br></br>

							<p>
								When you send messages or join meetings and webinars on SEEYOU,
								other people and organizations, including third parties outside
								the meeting, webinar, or message, may be able to see content and
								information that you share:
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									<strong>Account Owner:</strong> An account owner is the
									organization or individual that signs up for a SEEYOU account.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Meeting Hosts and Participants:</strong> Hosts and
									other participants in a meeting may be able to see your email,
									display name, and profile picture.
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Third-Party Apps and Integrations:</strong>
									<ul style={style.bulletList}>
										<li style={style.bulletItem}>
											Account owners can choose to add third-party apps to their
											account and the SEEYOU Products they use.
										</li>

										<br></br>

										<li style={style.bulletItem}>
											Third-party developers may also integrate or embed SEEYOU
											meetings into their website or app experiences or build
											versions of SEEYOU that enable access to SEEYOU Products
											from a third-party app.
										</li>

										<br></br>

										<li style={style.bulletItem}>
											Personal information shared by account owners and users
											with third-party apps and integrations is collected and
											processed in accordance with the app developers’ terms and
											privacy policies, not SEEYOU’s.
										</li>
									</ul>
								</li>
							</ul>

							<br></br>

							<h2 style={style.title}>Privacy Rights and Choices</h2>

							<br></br>

							<p>
								If you are in the European Economic Area (EEA), Switzerland, or
								the UK, please refer to the respective dedicated sections below.
								Otherwise, at your request, and as required by applicable law,
								we will:
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									Inform you of what personal data we have about you that is
									under our control;
								</li>

								<br></br>

								<li style={style.bulletItem}>
									Amend or correct such personal data or any previous privacy
									preferences you selected, or direct you to applicable tools;
									and/or
								</li>

								<br></br>

								<li style={style.bulletItem}>
									Delete such personal data or direct you to applicable tools.
								</li>
							</ul>

							<br></br>

							<h2 style={style.title}>Children</h2>

							<br></br>

							<p>
								SEEYOU does not allow children under the age of 13 to sign up
								for a SEEYOU account. For educational organizations that use
								SEEYOU Products to provide educational services to children
								under 13.
							</p>

							<br></br>

							<h2 style={style.title}>How to Contact Us</h2>

							<br></br>

							<p>
								If you have any privacy-related questions or comments related to
								this Privacy Statement, please send an email to{' '}
								<a
									target="_blank"
									style={style.link}
									href="mailto:hello@seeyou.eu"
								>
									hello@seeyou.eu
								</a>
							</p>

							<br></br>

							<h2 style={style.title}>Retention</h2>

							<br></br>

							<p>
								We retain personal data for as long as required to engage in the
								uses described in this Privacy Statement, unless a longer
								retention period is required by applicable law.
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									The length of time we have an ongoing relationship with you
									and provide SEEYOU Products to you (for example, for as long
									as you have an account with us or keep using our Products);
								</li>

								<br></br>

								<li style={style.bulletItem}>
									Whether account owners modify or their users delete
									information through their accounts;
								</li>

								<br></br>

								<li style={style.bulletItem}>
									Whether we have a legal obligation to keep the data (for
									example, certain laws require us to keep records of your
									transactions for a certain period of time before we can delete
									them); or
								</li>

								<br></br>

								<li style={style.bulletItem}>
									Whether retention is advisable in light ofour legal position
									(such as in regard to the enforcement of our agreements, the
									resolution of disputes, and applicable statutes of
									limitations, litigation, or regulatory investigation).
								</li>
							</ul>

							<br></br>

							<h2 style={style.title}>
								European Data Protection Specific Information
							</h2>

							<br></br>

							<h2 style={style.title}>Data Subjects Rights</h2>

							<br></br>

							<p>
								If you are in the EEA, Switzerland, or the UK, your rights in
								relation to your personal data processed by us as a controller
								specifically include:
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									<strong>Right of access and/or portability:</strong> You have
									the right to access any personal data that we hold about you
									and, in some circumstances, have that data provided to you so
									that you can provide or “port” that data to another provider;
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Right of erasure:</strong> In certain circumstances,
									you have the right to the erasure of personal data that we
									hold about you (for example, if it is no longer necessary for
									the purposes for which it was originally collected);
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Right to object to processing:</strong> In certain
									circumstances, you have the right to request that we stop
									processing your personal data and/or stop sending you
									marketing communications;
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Right to rectification:</strong> You have the right to
									require us to correct any inaccurate or incomplete personal
									data;
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Right to restrict processing:</strong> You have the
									right to request that we restrict processing of your personal
									data in certain circumstances (for example, where you believe
									that the personal data we hold about you is not accurate or
									lawfully held).
								</li>
							</ul>

							<br></br>

							<p>
								To exercise your rights, please send an email to{' '}
								<a
									target="_blank"
									style={style.link}
									href="mailto:hello@seeyou.eu"
								>
									hello@seeyou.eu
								</a>
								. If you have any other questions about our use of your personal
								data, please send a request at the contact details specified in
								the How to Contact Us section of this Privacy Statement. Please
								note that we may request you to provide us with additional
								information in order to confirm your identity and ensure that
								you are entitled to access the relevant personal data.
							</p>

							<br></br>

							<p>
								You also have the right to lodge a complaint to a data
								protection authority. For more information, please contact your
								local data protection authority.
							</p>

							<br></br>

							<h2 style={style.title}>
								Legal Basis for Processing Personal Data
							</h2>

							<br></br>

							<p>
								We only use your information in a lawful, transparent, and fair
								manner. Depending on the specific personal data concerned and
								the factual context, when SEEYOU processes personal data as a
								controller for individuals in regions such as the EEA,
								Switzerland, and the UK, we rely on the following legal bases as
								applicable in your jurisdiction:
							</p>

							<br></br>

							<ul style={style.bulletList}>
								<li style={style.bulletItem}>
									<strong>As necessary for our contract:</strong> When we enter
									into a contract directly with you, we process your personal
									data on the basis of our contract in order to prepare and
									enter into the contract, as well as to perform and manage our
									contract (i.e., providing SEEYOU Products, features and
									services to account owners, their users, and those they invite
									to join meetings and webinars hosted on their accounts, and
									manage our relationship and contract, including billing,
									compliance with contractual obligations, and related
									administration). If we do not process your personal data for
									these purposes, we may not be able to provide you with all
									Products, features, and services;
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>Consistent with specific revocable consents:</strong>{' '}
									We rely on your prior consent in order to utilize cookies to
									engage advertising and analytics partners to deliver tailored
									advertising and analysis of our website usage. You have the
									right to withdraw your consent at any time by visiting our
									cookie management tool, available Cookies Settings;
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>
										As necessary to comply with our legal obligations:
									</strong>{' '}
									We process your personal data to comply with the legal
									obligations to which we are subject for the purposes of
									compliance with EEA laws, regulations, codes of practice,
									guidelines, or rules applicable to us, and for responses to
									requests from, and other communications with, competent EEA
									public, governmental, judicial, or other regulatory
									authorities. This includes detecting, investigating,
									preventing, and stopping fraudulent, harmful, unauthorized, or
									illegal activity (“fraud and abuse detection”) and compliance
									with privacy laws;
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>
										To protect your vital interests or those of others:
									</strong>{' '}
									We process certain personal data in order to protect vital
									interests for the purpose of detecting and preventing illicit
									activities that impact vital interests and public safety,
									including child sexual abuse material; and
								</li>

								<br></br>

								<li style={style.bulletItem}>
									<strong>
										As necessary for our (or others’) legitimate interests,
										unless those interests are overridden by your interests or
										fundamental rights and freedoms, which require protection of
										personal data:
									</strong>{' '}
									We process your personal data based on such legitimate
									interests to (i) enter and perform the contract with the
									account owner and/or reseller providing you with the Products
									(which includes billing, compliance with contractual
									obligations, and related administration and support); (ii)
									develop, test, and improve our Products and troubleshoot
									products and features; (iii) ensure authentication, integrity,
									security, and safety of accounts, activity, and Products,
									including detect and prevent malicious conduct and violations
									of our terms and policies, prevent or investigate bad or
									unsafe experiences, and address security threats; (iv) send
									marketing communications, advertising, and promotions related
									to the Products; and (v) comply with non-EEA laws,
									regulations, codes of practice, guidelines, or rules
									applicable to us and respond to requests from, and other
									communications with, competent non-EEA public, governmental,
									judicial, or other regulatory authorities, as well as meet our
									corporate and social responsibility commitments, protect our
									rights and property and the ones of our customers, resolve
									disputes, and enforce agreements.
								</li>
							</ul>

							<br></br>

							<h2 style={style.title}>International Data Transfers</h2>

							<br></br>

							<p>
								SEEYOU operates globally, which means personal data may be
								transferred, stored (for example, in a data center), and
								processed outside of the country or region where it was
								initially collected where SEEYOU or its service providers have
								customers or facilities – including in countries where meeting
								participants or account owners hosting meetings or webinars that
								you participate in or receiving messages that you send are
								based.
							</p>

							<br></br>

							<p>
								Therefore, by using SEEYOU Products or providing personal data
								for any of the purposes stated above, you acknowledge that your
								personal data may be transferred to or stored in other countries
								outside of the EEA, Switzerland, and the UK. Such countries may
								have data protection rules that are different and less
								protective than those of your country.
							</p>

							<br></br>

							<p>
								We protect your personal data in accordance with this Privacy
								Statement wherever it is processed and take appropriate
								contractual or other steps to protect it under applicable laws.
								Where personal data of users in the EEA, Switzerland, or the UK
								is being transferred to a recipient located in a country outside
								the EEA, Switzerland, or the UK which has not been recognized as
								having an adequate level of data protection, we ensure that the
								transfer is governed by the European Commission’s standard
								contractual clauses. Please contact us if you would like further
								information in that respect.
							</p>

							<br></br>

							<h2 style={style.title}>Changes to This Privacy Statement</h2>

							<br></br>

							<p>
								We may update this Privacy Statement periodically to account for
								changes in our collection and/or processing of personal data,
								and will post the updated Privacy Statement on our website, with
								a “Last Updated” date at the top. If we make material changes to
								this Privacy Statement, we will notify you and provide you an
								opportunity to review before you choose to continue using our
								Products.
							</p>
						</div>
					</GlobalBox>
				</Container>
			</PrivacySection>
		</>
	);
};
export default PrivacyHero;
