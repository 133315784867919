import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalStyle } from '../GlobalStyle';
import {
	Nav,
	Link,
	Header,
	LogoImg,
	NavItem,
	ButtonItem,
	NavList,
	ButtonList,
	HeaderBox,
	LogoLink,
	HeaderContainer,
	NavLoginLink,
	CustomBurger,
} from './AppBar.styled';
import LogoSeeYou from 'image/seeyou_logo.png';
import { useOnClickOutside } from 'hooks/OnClickOutside';
import PopupMenu from 'components/PopupMenu';
import { theme } from 'constants/theme';

const confLinks = [
	// { to: '/providers-google', content: 'Google Meet' },
	// { to: '/providers-microsoft', content: 'Microsoft Teams' },
	// { to: '/providers-webex', content: 'Webex by Cisco' },
	{ to: '/providers-zoom', content: 'Zoom' },
	// { to: '/providers-bluejeans', content: 'Bluejeans' },
	// { to: '/providers-huawei', content: 'Huawei' },
	// { to: '/providers-starleaf', content: 'Starleaf' },
];

const moreLinks = [
	{ to: '/terms', content: 'Terms of Service' },
	{ to: '/privacy', content: 'Privacy Policy' },
	{ to: '/delete-account', content: 'Delete Account' },
	{ to: '/end-to-end-encryption', content: 'E2E Encryption' },
	{ to: '/problem', content: 'Report a Problem' },
	{ to: '/feature', content: 'Suggest a Feature' },
	// {
	// 	to: 'https://seeyou.launchnotes.io/',
	// 	content: 'Release Notes',
	// 	target: '_blank',
	// },
];

const ButtonsGroup = () => {
	return (
		<ButtonList>
			<ButtonItem>
				<NavLoginLink href={process.env.REACT_APP_WEB_BASE_URL} target="_blank">
					Login
				</NavLoginLink>
			</ButtonItem>
		</ButtonList>
	);
};

const AppBar = () => {
	const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	const handleLinkClick = () => {
		toggleMenu();
	};
	const toggleMenu = () => {
		setIsOpenMobileMenu(!isOpenMobileMenu);
	};
	const node = useRef();
	useEffect(() => {
		setScreenWidth(window.innerWidth);
	}, [window.innerWidth]);

	useOnClickOutside(node, () => toggleMenu(false), isOpenMobileMenu);

	const { pathname } = useLocation();
	const isDownloadPage = pathname === '/downloads';
	return (
		<>
			<GlobalStyle isDownloadPage={isDownloadPage} />
			<Header ref={node}>
				<HeaderContainer>
					<CustomBurger
						open={isOpenMobileMenu}
						setOpen={toggleMenu}
						aria-expanded={isOpenMobileMenu}
						type="button"
					/>
					<HeaderBox>
						<LogoLink onClick={() => setIsOpenMobileMenu(false)} to="/">
							<LogoImg src={LogoSeeYou} alt={'SEEYOU'} />
						</LogoLink>
					</HeaderBox>
					<Nav className={isOpenMobileMenu && 'active'}>
						<NavList>
							<NavItem>
								<Link to="/" onClick={handleLinkClick}>
									Home
								</Link>
							</NavItem>
							<NavItem>
								<Link to="/about" onClick={handleLinkClick}>
									About
								</Link>
							</NavItem>
							<NavItem>
								<PopupMenu
									height={'60px'}
									links={confLinks}
									triggerName={'Conference Providers'}
									onClick={handleLinkClick}
								/>
							</NavItem>
							<NavItem>
								<Link to="/faq" onClick={handleLinkClick}>
									FAQ
								</Link>
							</NavItem>
							<NavItem>
								<PopupMenu
									links={moreLinks}
									triggerName={'More'}
									onClick={handleLinkClick}
								/>
							</NavItem>
							{screenWidth < parseInt(theme.size.tablet) ? (
								<NavItem>
									<ButtonsGroup />
								</NavItem>
							) : (
								''
							)}
						</NavList>
					</Nav>
					{screenWidth >= parseInt(theme.size.tablet) ? <ButtonsGroup /> : ''}
				</HeaderContainer>
			</Header>
		</>
	);
};
export default AppBar;
